import { InputText } from '@f/core/forms/inputs'
import { Form } from '@f/core/forms/Form'
import { required } from 'utils/inputs/validation'
import { ClientDetails } from './ClientDetails'
import ClientDetailsEdit from '~/shop/front/components/userProfile/ClientDetails/ClientDetailsEdit'

class ClientDetailsForm extends ClientDetails {
	status = ''
	processing = false
	form = false
	component = ClientDetailsEdit

	get translations () {
		return this.app.translator.get('forms.clientDetails')
	}

	async init () {
		await super.init()
		await this.app.translator.prefetch('client-profile')

		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.inputFirstName'),
			attribute: 'firstName',
			name: 'firstName',
			value: this.client.firstName,
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.inputLastName'),
			attribute: 'lastName',
			name: 'lastName',
			value: this.client.lastName,
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.companyName'),
			attribute: 'companyName',
			name: 'companyName',
			value: this.client.companyName ? this.client.companyName : '',
			validation: []
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.streetAndBuildingNumber'),
			attribute: 'streetAndBuildingNumber',
			name: 'streetAndBuildingNumber',
			value: this.client.streetAndBuildingNumber ? this.client.streetAndBuildingNumber : '',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.city'),
			attribute: 'city',
			name: 'city',
			value: this.client.city ? this.client.city : '',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.postalCode'),
			attribute: 'postalCode',
			name: 'postalCode',
			mask: '##-###',
			inputmode: 'numeric',
			value: this.client.postalCode ? this.client.postalCode : '',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.phoneNumber'),
			attribute: 'phoneNumber',
			name: 'phoneNumber',
			mask: '+48 ### ### ###',
			inputmode: 'numeric',
			value: this.client.phoneNumber ? this.client.phoneNumber : '',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.clientDetails.inputEmail'),
			attribute: 'email',
			name: 'email',
			value: this.client.user.email,
			disable: true,
			validation: [required()]
		}))
	}

	async updateClientData () {
		if (!await this.form.validate()) return false
		try {
			this.processing = true
			const data = this.form.getValues()

			const service = this.app.getService('rext')
			const { error } = await service.editClient(data)

			this.processing = false

			if (error) {
				this.status = {
					message: this.translations.status.PROCESSING_ERROR,
					code: 'error'
				}
				return
			}
			this.status = {
				message: this.translations.status.SUCCESS,
				code: 'correct'
			}
		} catch (err) {
			console.error(err)
			this.status = {
				message: this.translations.status.SERVER_ERROR,
				code: 'error'
			}
		}
	}
}
export {
	ClientDetailsForm
}

import { objectCopyByJSON } from 'utils/objects'
import ClientDetailsComponent from '~/shop/front/components/userProfile/ClientDetails/ClientDetails'

class ClientDetails {
	client = {}
	component = ClientDetailsComponent

	constructor (app) {
		this.app = app
	}

	get translations () {
		return this.app.translator.get('forms.clientDetails')
	}

	async getClientData () {
		const service = this.app.getService('rext')
		const client = await service.getMe()
		const data = objectCopyByJSON(client)
		data.email = client.user.email
		return data
	}

	async init () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('client-profile')
		await this.app.translator.prefetch('forms.clientDetails')

		this.client = await this.getClientData()
	}
}

export {
	ClientDetails
}

<template>
	<div class="client-details" v-if="currentPageView">
		<base-font variant="medium-header" marginReset>
			{{translations.client_data.heading}}
		</base-font>
		<div class="client-data-wrapper">
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.name}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.firstName}}</base-font>
			</div>
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.surname}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.lastName}}</base-font>
			</div>
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.company}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.companyName}}</base-font>
			</div>
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.streetAndApartment}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.streetAndBuildingNumber}}</base-font>
			</div>
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.city}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.city}}</base-font>
			</div>
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.postal_code}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.postalCode}}</base-font>
			</div>
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.phone_number}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.phoneNumber}}</base-font>
			</div>
			<div class="client-data-item">
				<base-font variants="menu" tag="h3" marginReset>{{translations.client_data.email}}:</base-font>
				<base-font tag="p" weight="medium" marginReset>{{client.email}}</base-font>
			</div>
		<base-button to="user-profile" :params="{view: userProfileViews['client-details-edit']}" type="secondary">
			{{translations.client_data.edit}}
		</base-button>
		</div>
	</div>
</template>
<script>
import { UserProfileViews } from '~/shop/front/core/pages/UserProfile/UserProfile'

export default {
	components: {
	},
	computed: {
		page () {
			return this.$app.page
		},
		currentPageView () {
			return this.page.view
		},
		client () {
			return this.currentPageView.client
		},
		translations () {
			return this.$app.translator.get('client-profile')
		},
		userProfileViews () {
			return UserProfileViews
		}
	}
}
</script>
<style lang="scss" scoped>

.client-details {
	:deep(.btn) {
		margin-bottom: 0;
	}
	.client-data-wrapper {
		margin-top: 3rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.client-data-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 1rem 2rem;

		&:nth-of-type(odd) {
			background: $white;
		}
	}
}
</style>
